import './styles.css';
import React from 'react';
import Calendar from 'react-calendar';
import "react-calendar/dist/Calendar.css";

export default class calendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      date: new Date(2019, 4, 17),
      //月のデータ
      month_days: {
        20240808: { text: 'グラスビュー' },
        20240814: { text: 'ワークショップ' }
      }
    };
    this.getTileClass = this.getTileClass.bind(this);
    this.getTileContent = this.getTileContent.bind(this);
  }

  // state の日付と同じ表記に変換
  getFormatDate(date) {
    return `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}${('0' + date.getDate()).slice(-2)}`;
  }

  //日付のクラスを付与 (祝日用)
  getTileClass({ date, view }) {
    // 月表示のときのみ
    if (view !== 'month') {
      return '';
    }
    const day = this.getFormatDate(date);
    return (this.state.month_days[day] && this.state.month_days[day].is_holiday) ?
      'holiday' : '';
  }

  //日付の内容を出力
  getTileContent({ date, view }) {
    // 月表示のときのみ
    if (view !== 'month') {
      return null;
    }
    const day = this.getFormatDate(date);

    var Tue = [
      "20230807",
      "20230808",
      "20230814",
      "20230815",
      "20230821",
      "20230822",
      "20230828",
      "20230829",
      "20230904",
      "20230905",
      "20230911",
      "20230912",
      "20230918",
      "20230919",
      "20230925",
      "20230926",
      "20231002",
      "20231003",
      "20231009",
      "20231010",
      "20231016",
      "20231017",
      "20231023",
      "20231024",
      "20231030",
      "20231031",
      "20231106",
      "20231107",
      "20231113",
      "20231114",
      "20231120",
      "20231121",
      "20231127",
      "20231128",
      "20231204",
      "20231205",
      "20231211",
      "20231212",
      "20231218",
      "20231219",
      "20231225",
      "20231226",
      "20240101",
      "20240102",
      "20240108",
      "20240109",
      "20240115",
      "20240116",
      "20240122",
      "20240123",
      "20240129",
      "20240130",
      "20240205",
      "20240206",
      "20240212",
      "20240213",
      "20240219",
      "20240220",
      "20240226",
      "20240227",
      "20240304",
      "20240305",
      "20240311",
      "20240312",
      "20240318",
      "20240319",
      "20240325",
      "20240326",
      "20240401",
      "20240402",
      "20240408",
      "20240409",
      "20240415",
      "20240416",
      "20240422",
      "20240423",
      "20240430",
      "20240506",
      "20240507",
      "20240513",
      "20240514",
      "20240520",
      "20240521",
      "20240527",
      "20240528",
      "20240603",
      "20240604",
      "20240610",
      "20240611",
      "20240617",
      "20240618",
      "20240619",
      "20240624",
      "20240625",
      "20240701",
      "20240702",
      "20240708",
      "20240709",
      "20240716",
      "20240717",
      "20240722",
      "20240723",
      "20240729",
      "20240730",
      "20240804",
      "20240805",
      "20240806",
      "20240807",
      "20240811",
      "20240812",
      "20240813",
      "20240818",
      "20240819",
      "20240820",
      "20240821",
      "20240825",
      "20240826",
      "20240827",
      "20240902",
      "20240903",
      "20240909",
      "20240910",
      "20240917",
      "20240918",
      "20240924",
      "20240925",
      "20240930",
      "20241001",
      "20241007",
      "20241008",
      "20241009",
      "20241015",
      "20241016",
      "20241021",
      "20241022",
      "20241028",
      "20241029",
      "20241105",
      "20241106",
      "20241111",
      "20241112",
      "20241118",
      "20241119",
      "20241120",
      "20241125",
      "20241126",
      "20241202",
      "20241203",
      "20241209",
      "20241210",
      "20241216",
      "20241217",
      "20241218",
      "20241219",
      "20241220",
      "20241226",
      "20241227",
      "20250101",
      "20250102",
      "20250103",
      "20250106",
      "20250107",
      "20250113",
      "20250114",
      "20250120",
      "20250121",
      "20250127",
      "20250128",
      "20250203",
      "20250204",
      "20250205",
      "20250210",
      "20250212",
      "20250217",
      "20250218",
      "20250225",
      "20250226",
      "20250304",
      "20250305",
      "20250310",
      "20250311",
      "20250312",
      "20250313",
      "20250317",
      "20250318",
      "20250324",
      "20250325",
      "20250331",
      "20250401",
      "20250402",
      "20250407",
      "20250408",
      "20250414",
      "20250415",
      "20250421",
      "20250422",
      "20250423",
      "20250430",
      "20250505",
      "20250506",
      "20250512",
      "20250513",
      "20250519",
      "20250520",
      "20250526",
      "20250527",
      "20250602",
      "20250603",
      "20250609",
      "20250610",
      "20250616",
      "20250617",
      "20250623",
      "20250624",
      "20250630",
      "20250701",
      "20250707",
      "20250708",
      "20250714",
      "20250715",
      "20250721",
      "20250722",
      "20250728",
      "20250729",
      "20250804",
      "20250805",
      "20250811",
      "20250812",
      "20250818",
      "20250819",
      "20250825",
      "20250826",
      "20250901",
      "20250902",
      "20250908",
      "20250909",
      "20250915",
      "20250916",
      "20250922",
      "20250923",
      "20250929",
      "20250930",
      "20251006",
      "20251007",
      "20251013",
      "20251014",
      "20251020",
      "20251021",
      "20251027",
      "20251028",
      "20251103",
      "20251104",
      "20251110",
      "20251111",
      "20251117",
      "20251118",
      "20251124",
      "20251125",
      "20251201",
      "20251202",
      "20251208",
      "20251209",
      "20251215",
      "20251216",
      "20251222",
      "20251223",
      "20251229",
      "20251230",
    ]

    return (
      <p style={Tue.includes(day) ? {color : 'white', backgroundColor : '#E0405C', fontSize : '8px', fontWeight : 'bold', borderRadius : '2px'} : {}}>
        {Tue.includes(day) ? '定休日' : <br />}
      </p>
    );
  }

  render() {
    return (
      <>
      <div className='calendar'>
        <Calendar
          locale="ja-JP" calendarType="US"
          value={new Date()}
          tileClassName={this.getTileClass}
          tileContent={this.getTileContent}
        />
      </div>
      </>
    );
  }
}